import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Image } from '../../image'
import './sticky-contact.scss'
import LargeBtnImage from './../../../../images/sticky-contact@2x.png'
import BtnImage from './../../../../images/sticky-contact.png'
import { Link } from 'gatsby'
import { animationType, createAnimationType, doAnimate } from '../../../../utils/functions'
import { TimelineLite } from 'gsap/all'
import { connect } from 'react-redux'

function StickyContact({translations, lang}) {
    const bgRef = useRef()
    createAnimationType();
    const [showBadge, setShowBadge] = useState(false);

    const animate = useCallback(() => {
        const tl = new TimelineLite({yoyo: true, repeat: 10, repeatDelay: 10});
        tl.from(bgRef.current, {width: 0, autoAlpha: 0, duration: 1, ease: animationType})
        tl.from('.textNode', {autoAlpha:0, ease: animationType})
            .repeat(-1);
    }, []);

    useEffect(() => {
        if(doAnimate()){
            setShowBadge(true);
            if(showBadge === true){
                animate();
            }
        }
    }, [animate, showBadge])

    
    return (
        <Link className="sticky-contact" to={lang === 'de' ? '/contact-us' : '/en/contact-us'} >
            <div className="flex">
                {showBadge && <div ref={bgRef} className="bg-sticky-contact flex column">
                    <div className="textNode">{ translations.sticky_contact_white }</div>
                    <div className="textNode yellow">{ translations.sticky_contact_yellow } <span className="icon-forward"></span></div>
                </div>}
                <Image img={BtnImage} img2={LargeBtnImage} alt="Contact Us"/>
            </div>
        </Link>
    )
}

const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang
})

export default connect(mapStateToProps)( StickyContact)
