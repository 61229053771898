import React from 'react'
import './langswitcher.scss'
import { useState } from 'react'
import { useEffect } from 'react';
import { isEnglish } from '../../../utils/functions';
import { navigate } from 'gatsby';
import { changeScrollPos } from '../../../actions/preferences';
import { connect } from 'react-redux';


function LanguageSwitcher({ changeScrollPos }) {

    let lang = !isEnglish() ? 'german' : 'english';

    const [language, setLanguage] = useState(lang);
    const [flagStyle, setFlagStyle] = useState('flag-de');

    useEffect(() => {
        setFlagStyle(language === 'german' ? 'flag-de' : 'flag-usa')
        changeScrollPos(0)
    }, [language, lang, changeScrollPos]);

    const handleChange = (lang) => {
        setLanguage(lang);
        if (typeof window !== 'undefined') {
            // Special da Unterverzeichnis und nicht Hauptdomain
            const p = window.location.hostname === 'dev.oe-konzept.de' ? window.location.pathname.split(process.env.PATH_PREFIX).join('') : window.location.pathname;
            const path = p;
            let lpath = path;
            if (lang === 'english' && !isEnglish()) {
                lpath = `/en${path}`
            } else if (lang === 'german' && isEnglish()) {
                lpath = path.split('en/').join('');
            }
            navigate(lpath);
        }
    }

    return (
        <div className="nav-wrapper">
            <div className="sl-nav">
                <ul>
                    <li><div className="sl-main-menu-wrapper">
                        <i className={`sl-flag ${flagStyle}`}></i>
                    </div>
                        <div className="triangle"></div>
                        <ul>
                            <button onClick={() => handleChange('german')}>
                                <li>
                                    <i className="sl-flag flag-de"><div id="germany"></div></i>
                                    <span className="active">De</span>
                                </li>
                            </button>
                            <button onClick={() => handleChange('english')}>
                                <li>
                                    <i className="sl-flag flag-usa"><div id="english"></div></i>
                                    <span>En</span>
                                </li>
                            </button>
                        </ul>
                    </li>
                </ul>
            </div>
        </div >
    )
}


export default connect(null, { changeScrollPos })(LanguageSwitcher);
