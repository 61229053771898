import axios from 'axios'

export const getData = async (type) => {
    const res = await fetch(`https://dev.oe-konzept.de/bodyshake/www/webroot/services/getCloudInformation.php?action=${type}`);
    let data = await res.json();
    return data;
}

// Wird aktuell genutzt
export const cloudApi = axios.create({
    baseURL: 'https://cloud.bodyshake.com/webroot/api',
    auth:{
        username: 'clouduser',
        password: '38zF>mz$m'
    }
})
