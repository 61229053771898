import { CHANGE_LANGUAGE, CHANGE_LANGUAGE_ERROR, CHANGE_TARGET, CHANGE_TARGET_ERROR, CHANGE_SCROLL_POS } from "./actionTypes";
import axios from 'axios'
import { persistValue } from "../utils/functions";

export const changeScrollPos = (pos) => dispatch => {
    try {

        dispatch({
            type: CHANGE_SCROLL_POS,
            payload: pos
        })

    } catch (error) {
        dispatch({
            type: CHANGE_TARGET_ERROR,
            payload: {
                msg: error.response ? error.response.statusText : error,
                status: error.response.status
            }
        })
    }
}



// Load language data
export const changeLanguage = (lang) => async dispatch => {
    try {
        const url = `${process.env.API_HOST}/translations.php?lang=${lang}`;
        const res = axios.get(url);
        const data = (await res).data;

        persistValue('lang', lang);

        dispatch({
            type: CHANGE_LANGUAGE,
            lang: lang,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CHANGE_LANGUAGE_ERROR,
            payload: {
                msg: error.response ? error.response.statusText : error,
                status: error.response.status
            }
        })
    }
}

// Change target (B2B <-> B2C)
export const changeTarget = (target) => async dispatch => {
    try {

        //if we want to save the last selected page (b2b or b2c) to restore on next load
        //maybe not necessary, since the link decides on which target page the user ends
        persistValue('target', target);

        dispatch({
            type: CHANGE_TARGET,
            payload: target
        })

    } catch (error) {
        dispatch({
            type: CHANGE_TARGET_ERROR,
            payload: {
                msg: error.response ? error.response.statusText : error,
                status: error.response.status
            }
        })
    }
}