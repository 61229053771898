/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header, { COOKIE_NAME_CAMPAIGN_TOOLBAR } from "./partials/header"
import Footer from "./partials/footer"
import FooterCust from "./partials/footerCust"
import "../css/layout.scss"
import SocialBar from "./ux/buttons/socialbar"
import Alert from "./../components/alert"
import StickyContact from "./ux/buttons/sticky-contact/sticky-contact"
import CookiePopup from "./ux/cookie/cookie-popup"
import { useCookies } from "react-cookie"
import { SET_GTM, SET_COOKIE_POPUP, SWITCH_MODE } from "../actions/actionTypes"
import { connect } from "react-redux"
import { getSiteModeRaw } from "../utils/functions"

const Layout = ({
  children,
  cutted,
  triggerUseGTM,
  setCookiePopupActive,
  setSiteModeRaw,
  rawmode,
  contactFormEnabled,
  target,
}) => {
  const [cookies] = useCookies()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [sizeMode, setSizeMode] = useState('large')

  const url = typeof window !== "undefined" ? window.location.href : ""
  const showStickyContact = url.indexOf("contact-us") === -1
  const cookiePopupAllowed =
    url.indexOf("privacy") === -1 && url.indexOf("legal-notice") === -1

  useEffect(() => {
    if (cookies.bodyshake_hinweis) {
      const res = cookies.bodyshake_hinweis
        .split(",")
        .reduce((acc, curr) => parseInt(acc) + parseInt(curr))
      if (res > 1) {
        triggerUseGTM()
      }
    }
    if (cookies[COOKIE_NAME_CAMPAIGN_TOOLBAR] === 'off') {
      setSizeMode('');
    }
    if (!cookies.bodyshake_cookie_set && cookiePopupAllowed) {
      setCookiePopupActive(true)
    }
    // Wenn Seite in iFram eingebunden wird, dann ohne Menu und Footer
    setSiteModeRaw(getSiteModeRaw())
  }, [
    cookies,
    setCookiePopupActive,
    triggerUseGTM,
    cookiePopupAllowed,
    setSiteModeRaw,
  ])


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setSizeMode('')
      } else {
        if (!cookies[COOKIE_NAME_CAMPAIGN_TOOLBAR]) {
          setSizeMode('large')
        }
      }
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [cookies]);

  return (
    <>
      <CookiePopup />
      <div id="page-grid" className={cutted === true ? "start" : ""}>
        {rawmode === false && (
          <>
            <div className={`headerbg ${sizeMode}`} />
            <Alert />
            {showStickyContact && contactFormEnabled && (target === 'b2b') === true && (
              <StickyContact />
            )}
            <Header siteTitle={data.site.siteMetadata.title} />
          </>
        )}
        <main className={cutted === true ? "cutted" : ""}>{children}</main>
        {rawmode === false && (
          <>
            <div className={target === "b2b" ? "topbar" : "topbar b2c"}></div>
            <div className={target === "b2b" ? "middlebar" : "middlebar b2c"}></div>
            {
              target === "b2b" ? <Footer showCTA={showStickyContact} /> : <FooterCust showCTA={showStickyContact} />
            }
          </>
        )}
        <SocialBar
          icons={["icon-facebook", "icon-youtube"]}
          links={[
            "https://www.facebook.com/BodyShake-642542565922426/",
            "https://www.youtube.com/channel/UCRy8pA9dgDDDbWvzn3yoOMw",
          ]}
        />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const mapDispatchToProps = dispatch => ({
  triggerUseGTM: () => {
    dispatch({ type: SET_GTM, payload: true })
  },
  setSiteModeRaw: flag => {
    dispatch({ type: SWITCH_MODE, payload: flag })
  },
  setCookiePopupActive: flag => {
    dispatch({ type: SET_COOKIE_POPUP, payload: flag })
  },
})

const mapStateToProps = state => ({
  rawmode: state.preferences.rawmode,
  contactFormEnabled: state.preferences.contactFormEnabled,
  target: state.preferences.target,
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
