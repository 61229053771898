import { Link } from "gatsby";
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import BlueButton from "../bluebutton";
import { changeLanguage } from "./../../../../actions/preferences";
import { changeTarget } from "./../../../../actions/preferences";
import LanguageSwitcher from "./../../language-switcher/langswitcher";
import Toggle from "./../../toggle/toggle";
import './navigation.scss';

function Navigation({ changeLanguage, translations, lang, classes }) {

    return (
        <nav className={classes}>
            <input type="checkbox" className="checker" aria-label="language-switch-control" />
            <span></span>
            <span></span>
            <span></span>
            <div className="menu-wrapper">
                <div className="menu">
                    {
                        lang === 'de' &&
                        <ul>
                            <li><Link exact="true" to="/machines/" activeClassName="active-menu">{translations.menu_2}</Link></li>
                            <li><Link exact="true" to="/testimonials/" activeClassName="active-menu">{translations.menu_8}</Link></li>
                            <li><Link exact="true" to="/mobile-app/" activeClassName="active-menu">{translations.menu_3}</Link></li>
                            <li><Link exact="true" to="/refillment/" activeClassName="active-menu">{translations.menu_5}</Link></li>
                            <li><Link exact="true" to="/contact/" activeClassName="active-menu">{translations.menu_7}</Link></li>
                        </ul>
                    }
                    {
                        lang === 'en' &&
                        <ul>
                            <li><Link exact="true" to="/en/machines/" activeClassName="active-menu">{translations.menu_2}</Link></li>
                            <li><Link exact="true" to="/en/testimonials/" activeClassName="active-menu">{translations.menu_8}</Link></li>
                            <li><Link exact="true" to="/en/mobile-app/" activeClassName="active-menu">{translations.menu_3}</Link></li>
                            <li><Link exact="true" to="/en/refillment/" activeClassName="active-menu">{translations.menu_5}</Link></li>
                            <li><Link exact="true" to="/en/contact/" activeClassName="active-menu">{translations.menu_7}</Link></li>
                        </ul>
                    }
                    <div className="customer-menu">
                        {
                            process.env.CONSUMER_PAGES_ACTIVE === 'true' &&
                            <>
                                <div className="target">
                                    <span>{translations.consumer}</span>
                                    <Toggle checked={false} />
                                    <span>{translations.business}</span>
                                </div>
                            </>
                        }
                        <LanguageSwitcher />
                        {
                            process.env.CLOUD_LOGIN_ACTIVE === 'true' &&
                            <a className="login-btn" href="https://cloud.bodyshake.com/">&nbsp;</a>
                        }
                    </div>
                    <div className="customer-menu-mobile">
                        {
                            process.env.CLOUD_LOGIN_ACTIVE === 'true' &&
                            <a href="https://cloud.bodyshake.com/" target="_blank" rel="noreferrer">
                                <BlueButton style={{ width: '50px' }}>
                                    <span className="icon-login" style={{ display: 'inline-block', fontSize: '16px' }}></span>Login
                                </BlueButton>
                            </a>
                        }
                        {
                            process.env.CONSUMER_PAGES_ACTIVE === 'true' &&
                            <>
                                <div className="target">
                                    <span>{translations.consumer}</span>
                                    <Toggle checked={false} />
                                    <span>{translations.business}</span>
                                </div>
                            </>
                        }
                        <LanguageSwitcher changeHandler={lang => changeLanguage(lang)} />
                    </div>
                </div>
            </div>
        </nav>
    )
}

Navigation.propTypes = {
    changeLanguage: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    changeTarget: PropTypes.func.isRequired,
    target: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    target: state.preferences.target
})

export default connect(mapStateToProps, { changeLanguage, changeTarget })(Navigation)
