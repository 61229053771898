import React from 'react'
import styles from './socialbar.module.scss'
import PropTypes from 'prop-types'


function SocialBar({icons, links}) {
    const showIt = process.env.SHOW_SOCIAL_LINKS;

    return (
        showIt === 'true' && <div className={styles.bar}>
            {
                icons.map((item, index) => (
                    <a key={index} href={links[index]} className={styles.link} target="_blank" rel="noreferrer">
                        <div className={styles.box}>
                            <span className={item}></span>
                        </div>
                    </a>
                ))
            }
        </div>
    )
}

SocialBar.propTypes = {
    icons: PropTypes.arrayOf(PropTypes.string),
    links: PropTypes.arrayOf(PropTypes.string)
}

export default SocialBar