import React from "react"
import { ScrollTrigger, TweenLite, CustomEase, ScrollToPlugin } from "gsap/all"
import gsap from "gsap"
import { navigate } from "gatsby"
gsap.registerPlugin(CustomEase, ScrollTrigger, ScrollToPlugin)

export const encodeEmail = (toEncode, withMailTo) => {
  let encodedAddress = ""

  for (let ii = 0; ii < toEncode.length; ii++) {
    encodedAddress = encodedAddress + "&#" + toEncode.charCodeAt(ii) + ";"
  }
  if (withMailTo === true) {
    encodedAddress = `&#109;&#97;&#105;&#108;&#116;&#111;&#58;${encodedAddress}`
  }

  return encodedAddress
}

// Custom Animation für Tweenlite für alle Animationen auf den Unterseiten
export const createAnimationType = () => {
  // Animationstyp
  CustomEase.create(
    "custom",
    "M0,0 C0.304,0 0.428,0.384 0.474,0.49 0.532,0.624 0.804,1 1,1 "
  )
}

export const animationType = "custom"

// Funktion, um alle Elemente nach Animation wieder in den Ausgangszustand zu bringen
// Elemente am Schluss wieder einblenden
export const resetElements = elements => {
  for (var elem of elements) {
    TweenLite.set(elem, { clearProps: "all" });
  }
}

// Funktion, um alle Elemente nach Animation wieder in den Ausgangszustand zu bringen
// Tweens zurücksetzen, aber Elemente am Schluss ausgeblendet lassen
export const resetElementsAndHide = elements => {
  for (var elem of elements) {
    TweenLite.set(elem, { clearProps: "all" });
    TweenLite.set(elem, { autoAlpha: 0 });
  }
}

// Funktion, um alle Tweens der Elemente zu killen (auch delayed calls)
export const killElementsTweens = elements => {
  for (var elem of elements) {
    TweenLite.killTweensOf(elem);
  }
}

/**
 * If Queryparam raw is set then prevent rendering menu, footer a.s.o.
 * @returns boolean
 */

export const getSiteModeRaw = () => {
  if (typeof window !== "undefined") {
    const queryString = window.location.search
    if (queryString.indexOf("raw=true") !== -1) {
      return true
    }
  }
  return false
}

// Baue für alle Scroll-Elemente einen ScrollTrigger
export const buildScrollTriggers = (
  scrollRefs,
  scrollEffect,
  scrollToRef,
  currentScrollPos,
  animationActive
) => {
  for (let i = 0; i < scrollRefs.length; i++) {
    ScrollTrigger.create({
      trigger: scrollRefs[i].current,
      start: "bottom 80%",
      onEnter: () => {
        if (i + 1 < scrollRefs.length && !animationActive.current) {
          // console.log(i);
          scrollEffect(i + 2)
          scrollToRef(scrollRefs[i + 1], true)
          currentScrollPos.current = i + 1
        }
      },
    })
    ScrollTrigger.create({
      trigger: scrollRefs[i].current,
      start: "bottom 80%",
      onEnterBack: ({ progress, direction, isActive }) => {
        if (!animationActive.current) {
          scrollEffect(i + 1)
          scrollToRef(scrollRefs[i], false)
          currentScrollPos.current = i + 1
        }
      },
    })
  }
}

// Tauscht Newlines durch HTML-BRs
export const nl2br = str => {
  if (!str) return null
  let arr = str.split(/\n/)
  let ret = arr.map((item, ind) => {
    if (ind < arr.length - 1) {
      return (
        <span key={ind}>
          {item}
          <br />
        </span>
      )
    } else {
      return <span key={ind}>{item}</span>
    }
  })
  return ret
}

export const doAnimate = () => {
  let isBrowser = typeof window !== "undefined",
    doIt = false
  if (isBrowser) {
    if (window.innerWidth >= 1400) {
      doIt = true
    }
  }
  return doIt
}

/**
 * Parse and return query params
 *
 * @return Object
 */

export const getUrlParameter = name => {
  if (typeof window !== "undefined") {
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
    var results = regex.exec(window.location.search)
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "))
  }
  return ""
}

export const doAnimateCarousel = () => {
  let isBrowser = typeof window !== "undefined",
    doIt = false
  if (isBrowser) {
    if (window.innerWidth >= 1400) {
      doIt = true
    }
  }
  return doIt
}

export const goToLead = lang => {
  if (typeof window !== `undefined`) {
    const uri = lang === "de" ? "/contact-us" : "/en/contact-us"
    navigate(uri)
  }
}

/**
 * Werte in den localstorage speichern, wenn im Browser
 * @param {*} key
 * @param {*} value
 */

export const persistValue = (key, value) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, value)
  }
}

/**
 * Werte aus dem localstorage lesen, wenn im Browser
 *
 * @param {*} key
 */
export const getPersistedValue = key => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(key)
  }
  return null
}

/**
 * Is current path the english one?
 *
 * @return boolean
 */
export const isEnglish = () => {
  if (typeof window !== "undefined") {
    return window.location.pathname.indexOf("/en") !== -1
  }
  return false
}

/**
 * Check the email address
 * @param {string} email Email to check
 * @returns bool
 */

export const checkIfEmailIsValid = email => {
  /* eslint-disable no-control-regex */
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
    email
  )
}

export const isProductionEnvironment = () => process.env.PROD === "true"
