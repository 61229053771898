import React, { forwardRef } from "react"


export const Image = ({ img, img2, alt = '', styles = '', id = '', onClick = null }) => {
  // If id set than add id to the image else add random id
  if (id === '') {
    id = Math.random().toString(36).substring(7);
  }
  return <img id={id} onClick={onClick} src={img} srcSet={`${img} 1x, ${img2} 2x`} alt={alt} className={styles} role="button" tabIndex={0} onKeyDown={e => onClick()} />
}

export const ImageRef = forwardRef(({ img, img2, alt, className = '' }, ref) => {
  return <img ref={ref} src={img} srcSet={`${img} 1x, ${img2} 2x`} alt={alt} className={className} />
});
