import React, { useEffect } from "react"

function CookiePopup() {

  /**
   * Scripttag für eRecht24 hinzufügen
   */

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://app.eu.usercentrics.eu/browser-ui/latest/loader.js"
    script.async = true
    script.id = 'usercentrics-cmp'
    script.setAttribute('data-settings-id', 'd8n5M_j8pkxVy4')
    script.setAttribute('data-eu-mode', 'true')
    document.body.appendChild(script)
  }, [])



  return (
    <>
    </>
  )
}


export default CookiePopup
