import { Link } from "gatsby"
import React from "react"
import { connect } from "react-redux"
import partnersImg from "../../images/partners.png"
import partnersImgLarge from "../../images/partners@2x.png"
import instagramImage from "../../images/instagram.png"
import instagramImageLarge from "../../images/instagram@2x.png"
import tiktokImage from "../../images/tiktok.png"
import tiktokImageLarge from "../../images/tiktok@2x.png"
import youtubeImage from "../../images/youtube.png"
import youtubeImageLarge from "../../images/youtube@2x.png"
import BlueButton from "../ux/buttons/bluebutton"
import Counter from "../ux/counter/counter"
import Hexagon from "../ux/hexagon/hexagon"
import { CHANGE_SCROLL_POS, SET_COOKIE_POPUP } from "./../../actions/actionTypes"
import { Image } from "./../../components/ux/image"
// Bilder
import Cup from "./../../images/cupCust.png"
import CupLarge from "./../../images/cupCust@2x.png"

function Footer({
  translations,
  showCTA,
  lang,
  openCookiePopup,
  changeScrollPos
}) {
  const showSocialLinks = process.env.SHOW_SOCIAL_LINKS === "true"

  if (typeof showCTA === "undefined") {
    showCTA = true
  }

  const onCookiePopupClicked = e => {
    e.preventDefault()
    openCookiePopup()
  }

  /* eslint-disable react/jsx-no-target-blank */

  return (
    <footer id="footer">
      <div className={`contact flex center socialCust`}>
        <a
          href={`https://www.instagram.com/bodyshakeofficial/`}
          className="flex center"
          target="_blank"
        >
          <Image
            img={instagramImage}
            img2={instagramImageLarge}
            alt="Instagram icon"
          />
        </a>
        <a
          href={`https://www.tiktok.com/@bodyshakeofficial`}
          className="flex center"
          target="_blank"
        >
          <Image
            img={tiktokImage}
            img2={tiktokImageLarge}
            alt="TikTok icon"
          />
        </a>
        <a
          href={`https://www.youtube.com/channel/UCRy8pA9dgDDDbWvzn3yoOMw`}
          className="flex center"
          target="_blank"
        >
          <Image
            img={youtubeImage}
            img2={youtubeImageLarge}
            alt="Yotube icon"
          />
        </a>
      </div>
      <div className="lead-button">
        {showCTA && (
          <div style={{ position: "relative" }}>
            <a className="mobile mobileBtnLink" href="#section_5_mobile"><span className="hide">App</span></a>
            <BlueButton onClick={() => changeScrollPos(5)}>
              <span className="icon-app-download"></span>
              {translations.customer_call_to_action}
            </BlueButton>
          </div>
        )}
      </div>
      <div className="counter">
        <Image img={Cup} img2={CupLarge} alt="Cup" />
        <Counter value={17864123} />
        <div className="shake-counter-text-cust">
          <div>{translations.sold_shakes_line_1}</div>
          <div>{translations.sold_shakes_line_2}</div>
        </div>
      </div>
      {showSocialLinks && (
        <div className="social">
          <a
            href="https://www.facebook.com/bodyshakemachines/"
            target="_blank"
            rel="noreferrer"
          >
            <Hexagon type="shape" color="#3B5C97">
              <span className="icon-facebook"></span>
            </Hexagon>
          </a>
          <a
            href="https://www.youtube.com/channel/UCRy8pA9dgDDDbWvzn3yoOMw"
            target="_blank"
            rel="noreferrer"
          >
            <Hexagon type="shape" color="red">
              <span className="icon-youtube"></span>
            </Hexagon>
          </a>
        </div>
      )}
      <div className="partners">
        <div className="flex center">
          <Image img={partnersImg} img2={partnersImgLarge} alt="Partners" />
        </div>
      </div>
      <div className="law">
        <a className="mr" href="/" onClick={onCookiePopupClicked}>
          {translations.cookie_preferences}
        </a>
        <Link
          to={lang === "en" ? "/en/legal-notice" : "/legal-notice"}
          className="mr"
        >
          {translations.imprint}
        </Link>
        <Link to={lang === "en" ? "/en/privacy" : "/privacy"} className="mr">
          {translations.privacy}
        </Link>
        {/* <Link to="/businessrules">{ translations.conditions } </Link> */}
      </div>
    </footer>
  )
}

const mapStateToProps = state => ({
  translations: state.preferences.translations,
  lang: state.preferences.lang,
  contactFormEnabled: state.preferences.contactFormEnabled,
  whatsAppNumber: state.preferences.whatsAppNumber,
  phoneNumber: state.preferences.phoneNumber,
  phoneNumberString: state.preferences.phoneNumberString
})

const mapDispatchToProps = dispatch => ({
  openCookiePopup: () => {
    dispatch({ type: SET_COOKIE_POPUP, payload: true })
  },
  changeScrollPos: (pos) => {
    dispatch({ type: CHANGE_SCROLL_POS, payload: pos })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
