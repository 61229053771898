import { Link } from "gatsby"
import React from "react"
import { connect } from "react-redux"
import partnersImg from "../../images/partners.png"
import partnersImgLarge from "../../images/partners@2x.png"
import whatsappImage from "../../images/whatsapp.png"
import whatsappImageLarge from "../../images/whatsapp@2x.png"
import bsLogoBlack from "../../images/bslogoblack.png"
import bsLogoBlackLarge from "../../images/bslogoblack@2x.png"
import phoneImage from "../../images/phone.png"
import phoneImageLarge from "../../images/phone@2x.png"
import { goToLead } from "../../utils/functions"
import BlueButton from "../ux/buttons/bluebutton"
import Counter from "../ux/counter/counter"
import Hexagon from "../ux/hexagon/hexagon"
import { SET_COOKIE_POPUP } from "./../../actions/actionTypes"
import { Image } from "./../../components/ux/image"
// Bilder
import colorStripe from "./../../images/colorstripe.png"
import colorStripeLarge from "./../../images/colorstripe@2x.png"
import fb from "./../../images/fb.png"
import fbLarge from "./../../images/fb@2x.png"
import yt from "./../../images/yt.png"
import ytLarge from "./../../images/yt@2x.png"
import insta from "./../../images/insta.png"
import instaLarge from "./../../images/insta@2x.png"
import twitter from "./../../images/twitter.png"
import twitterLarge from "./../../images/twitter@2x.png"
import linkedin from "./../../images/linkedin.png"
import linkedinLarge from "./../../images/linkedin@2x.png"

function Footer({
  translations,
  showCTA,
  lang,
  openCookiePopup,
  contactFormEnabled,
  whatsAppNumber,
  phoneNumber,
  phoneNumberString,
}) {
  const showSocialLinks = process.env.SHOW_SOCIAL_LINKS === "true"

  if (typeof showCTA === "undefined") {
    showCTA = true
  }

  /* eslint-disable react/jsx-no-target-blank */

  return (
    <footer id="footer">
      <div className={`contact flex sb as start`}>
        <a
          href={`https://api.whatsapp.com/send?phone=${whatsAppNumber}&text=Hallo%20BODYSHAKE%20%2C%20ich%20komme%20von%20eurer%20Website%20und%20habe%20folgendes%20Anliegen:%20`}
          className="flex center mobile-exclude"
          target="_blank"
        >
          <Image
            img={whatsappImage}
            img2={whatsappImageLarge}
            alt="WhatsApp icon"
          />
          <span>WhatsApp</span>
        </a>
        <a href={`tel:${phoneNumber}`} className="flex center">
          <Image
            img={phoneImage}
            img2={phoneImageLarge}
            alt="Phone"
          />
          <span>{phoneNumberString}</span>
        </a>
        <a
          href={`https://api.whatsapp.com/send?phone=${whatsAppNumber}&text=Hallo%20BODYSHAKE%20%2C%20ich%20komme%20von%20eurer%20Website%20und%20habe%20folgendes%20Anliegen:%20`}
          className="flex center mobile"
          target="_blank"
        >
          <Image
            img={whatsappImage}
            img2={whatsappImageLarge}
            alt="WhatsApp icon"
          />
          <span>WhatsApp</span>
        </a>
      </div>
      <div className="lead-button">
        {showCTA && (
          <BlueButton onClick={() => goToLead(lang)}>
            {translations.call_to_action}
          </BlueButton>
        )}
      </div>
      <div className="counter js start">
        <Counter value={17864123} />
        <div className="shake-counter-text">
          <div>{translations.sold_shakes_line_1}</div>
          <div>{translations.sold_shakes_line_2}</div>
        </div>
      </div>
      {showSocialLinks && (
        <div className="social">
          <a
            href="https://www.facebook.com/bodyshakemachines/"
            target="_blank"
            rel="noreferrer"
          >
            <Hexagon type="shape" color="#3B5C97">
              <span className="icon-facebook"></span>
            </Hexagon>
          </a>
          <a
            href="https://www.youtube.com/channel/UCRy8pA9dgDDDbWvzn3yoOMw"
            target="_blank"
            rel="noreferrer"
          >
            <Hexagon type="shape" color="red">
              <span className="icon-youtube"></span>
            </Hexagon>
          </a>
        </div>
      )}
      <div className="partners js start">
        <div className="flex column left">
          <Image img={bsLogoBlack} img2={bsLogoBlackLarge} alt="BodyShake" styles="bslogo" />
          <Image img={partnersImg} img2={partnersImgLarge} alt="Partners" styles="partnersLogo" />
        </div>
      </div>
      <div className="law flex column left">
        <p className="infomarker">Informationen</p>
        <Link
          to={lang === "en" ? "/en/legal-notice" : "/legal-notice"}
          className="mt-2"
        >
          {translations.imprint}
        </Link>
        <Link to={lang === "en" ? "/en/privacy" : "/privacy"} className="mt-2">
          {translations.privacy}
        </Link>
        {/* <Link to="/businessrules">{ translations.conditions } </Link> */}
      </div>
      <div className="social flex sb">
        <div className="colorstripe">
          <Image img={colorStripe} img2={colorStripeLarge} alt="Color stripe" />
        </div>
        <div>
          <a href="https://www.facebook.com/bodyshakeofficial" target="_blank" rel="noreferrer"><Image img={fb} img2={fbLarge} alt="Facebook" styles="mr" /></a>
          <a href="https://www.youtube.com/@bodyshakeoffical" target="_blank" rel="noreferrer"><Image img={yt} img2={ytLarge} alt="Youtube" styles="mr" /></a>
          <a href="https://www.instagram.com/bodyshakeofficial/" target="_blank" rel="noreferrer"><Image img={insta} img2={instaLarge} alt="Instagram" styles="mr" /></a>
          <a href="https://twitter.com/bodyshake_com" target="_blank" rel="noreferrer"><Image img={twitter} img2={twitterLarge} alt="Twitter" styles="mr" /></a>
          <a href="https://www.linkedin.com/company/bodyshakeofficial/" target="_blank" rel="noreferrer"><Image img={linkedin} img2={linkedinLarge} alt="Youtube" /></a>
        </div>
      </div>
    </footer>
  )
}

const mapStateToProps = state => ({
  translations: state.preferences.translations,
  lang: state.preferences.lang,
  contactFormEnabled: state.preferences.contactFormEnabled,
  whatsAppNumber: state.preferences.whatsAppNumber,
  phoneNumber: state.preferences.phoneNumber,
  phoneNumberString: state.preferences.phoneNumberString
})

const mapDispatchToProps = dispatch => ({
  openCookiePopup: () => {
    dispatch({ type: SET_COOKIE_POPUP, payload: true })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
