import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './toggle.module.scss'

function Toggle({ checked, opts, clickHandlerCallback, labelText, labelTextChecked, ...props }) {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    const handleClick = (e) => {
        setIsChecked(prev => {
            if (clickHandlerCallback) {
                clickHandlerCallback(!prev);
            }
            return !prev;
        });
    }

    return (
        <>
            <label className={styles.switch}>
                <input type="checkbox" checked={isChecked ? 'checked="checked"' : ''} {...opts} onChange={handleClick} aria-label={isChecked ? labelTextChecked : labelText} />
                <span className={`${styles.slider} ${styles.round}`} data-after={labelText ?? ''} data-after-checked={labelTextChecked ?? ''}></span>
            </label>
        </>
    )
}

Toggle.propTypes = {
    checked: PropTypes.bool
}

export default Toggle

