/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { connect } from "react-redux"
import { getUrlParameter } from "./../utils/functions"
import { SWITCH_PROMO } from "./../actions/actionTypes"
import OpenGraphImage from "./../images/Bodyshake_Open_Graph.png"

function SEO({ description, lang, meta, title, useGTM, removePromo }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  // Kampagne auf Startseite soll nicht ausgespielt werden
  const param = getUrlParameter("campaign")
  if (param === "leads2021") {
    removePromo()
  }

  const metaDescription = description || site.siteMetadata.description
  const location = typeof window !== "undefined" ? window.location : null
  let scriptArray = []
  scriptArray = [
    {
      type: "text/javascript",
      innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.GTM_ID}');`,
    }
  ]

  let langPathAlternate = ''
  let langAlternate = 'de'
  if (location) {
    langPathAlternate = location.pathname.indexOf('/en/') > -1 ? location.pathname.replace('/en/', '/') : `/en${location.pathname}`
    langAlternate = location.pathname.indexOf('/en/') > -1 ? 'de' : 'en'
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      /* titleTemplate={`%s | ${site.siteMetadata.title}`} */
      link={[
        {
          rel: "canonical",
          href: `${site.siteMetadata.siteUrl}${location ? location.pathname : ''}`,
        },
        {
          rel: "alternate",
          href: `${site.siteMetadata.siteUrl}${langPathAlternate}`,
          hreflang: langAlternate,
        }
      ]}
      meta={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=0, viewport-fit=cover',
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${typeof window !== "undefined" ? window.location.protocol + '//' + window.location.host : ''}${OpenGraphImage}`,
        },
        {
          property: `og:image:width`,
          content: "1910",
        },
        {
          property: `og:image:height`,
          content: "1000",
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `og:url`,
          content: `${site.siteMetadata.siteUrl}${location ? location.pathname : ''}`,
        },
      ].concat(meta)}
      script={scriptArray}
    ></Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  useGTM: state.preferences.useGTM,
})

const mapDispatchToProps = dispatch => ({
  removePromo: () => {
    dispatch({ type: SWITCH_PROMO, payload: false })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SEO)
