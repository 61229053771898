import React from 'react'
import PropTypes from 'prop-types'
import styles from './bluebutton.module.scss'

function BlueButton({ children, onClick }) {
    const clickHandler = () => {
        if(onClick){
            onClick();
        }else{
        }
    }

    return (
        <button className={`${styles.btn} blue-btn`} onClick={clickHandler}>
            { children }
        </button>
    )
}

BlueButton.propTypes = {
    children: PropTypes.node.isRequired
}

export default BlueButton

