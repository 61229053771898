import React from 'react'
import styles from './hexagon.module.scss'
import PropTypes from 'prop-types'
import { css } from 'glamor'

function Hexagon({ children, type, color }) {
    let style = {};
    let dynamicRules = css({
        backgroundColor: color, 
        '&::before':{borderBottom: `7px solid ${color}`}, 
        '&::after':{borderTop: `7px solid ${color}`}
    });
    switch(type){
        case 'gradient':
            style = styles.gradient;
        break;
        default:
            style = styles.shape
    }

    return (
        <div className={`${style} ${dynamicRules}`} style={{color: 'white'}} >
            {children}
        </div>
    )
}

Hexagon.propTypes = {
    type: PropTypes.string,
    children: PropTypes.node.isRequired,
    color: PropTypes.string
  }

export default Hexagon
