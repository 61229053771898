import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import logo from '../../images/bsfont.svg'
import { changeLanguage } from '../../actions/preferences'
import { changeTarget } from '../../actions/preferences'

import Navigation from "../ux/buttons/navigation/navigation"
import { useCookies } from "react-cookie"
import { nl2br } from "../../utils/functions"

export const COOKIE_NAME_CAMPAIGN_TOOLBAR = 'bodyshake-campaign-toolbar'

const Header = ({ siteTitle, changeLanguage, lang, translations }) => {
  const [cookies, setCookie] = useCookies(COOKIE_NAME_CAMPAIGN_TOOLBAR)
  const [stripeClass, setStripeClass] = useState('closed')

  useEffect(() => {
    const lang = typeof window !== 'undefined' && window.location.pathname.indexOf('/en') !== -1 ? 'en' : 'de';
    changeLanguage(lang)
  }, [changeLanguage])

  useEffect(() => {
    if (cookies[COOKIE_NAME_CAMPAIGN_TOOLBAR] === 'off') {
      setStripeClass('closed')
    } else {
      setStripeClass('')
    }
  }, [cookies])



  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setStripeClass('closed')
      } else {
        if (!cookies[COOKIE_NAME_CAMPAIGN_TOOLBAR]) {
          setStripeClass('')
        }
      }
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [cookies]);



  const deactivateToolbar = () => {
    setCookie(COOKIE_NAME_CAMPAIGN_TOOLBAR, 'off', { path: '/' })
    setStripeClass('closed')
  }

  return (
    <header>
      <div className={`stripe ${stripeClass}`}>
        <div className="stripe-content t-upper">
          {nl2br(translations.campaign_toolbar_text_1)} {nl2br(translations.campaign_toolbar_text_2)} {nl2br(translations.campaign_toolbar_text_3)}
        </div>
        <Link to="#" onClick={deactivateToolbar}>
          <div className="close-campaign"  >
            <svg aria-hidden="true" focusable="false" role="presentation" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.48627 9.32917L2.82849 3.67098" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
              <path d="M2.88539 9.38504L8.42932 3.61524" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div>
        </Link>
      </div>
      {
        lang === 'de' && <Link to="/"><img src={logo} alt="Bodyshake" className={stripeClass === 'closed' ? '' : 'mb--40'} /></Link>
      }
      {
        lang === 'en' && <Link to="/en/"><img src={logo} alt="Bodyshake" className={stripeClass === 'closed' ? '' : 'mb--40'} /></Link>
      }
      <Navigation classes={stripeClass === 'closed' ? 'closed' : 'not-closed'} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const mapStateToProps = state => ({
  lang: state.preferences.lang,
  translations: state.preferences.translations,
  target: state.preferences.target
});

export default connect(mapStateToProps, { changeLanguage, changeTarget })(Header);

/*
{
  target === 'b2b' ? <Navigation /> : <NavigationCust />
}
*/