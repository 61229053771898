import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";
import React, { useEffect, useRef, useState } from "react";
import { cloudApi } from './../../../data/api';
import { connect } from 'react-redux';
import "./counter.scss";


function Counter({ value, target }) {
  const divRef = useRef();
  const tickRef = useRef();

  const [tickValue, setTickValue] = useState(value);
  const [cnt, setCnt] = useState(0)
  const [visible, setVisible] = useState(true);

  // Make the Tick instance and store it in the refs
  useEffect(() => {
    const didInit = tick => {
      tickRef.current = tick;
    };


    const currDiv = divRef.current;

    Tick.DOM.create(currDiv, {
      value,
      didInit
    });
    return () => Tick.DOM.destroy(currDiv);
  }, [value]);

  const errorHandler = (error) => {
    console.error(error);
    setVisible(false);
  }


  // Start the Tick.down process
  useEffect(() => {

    let container = divRef.current;
    let isApiSubscribed = true;

    const retrieveData = async (diff = 0) => {
      const res = cloudApi.get('/Sales/getTotalSalesCount');
      const data = (await res).data;
      if (isApiSubscribed) {
        const sales = data.records.total;
        if (sales && !isNaN(sales)) {
          // Wunsch von Steve, pro Tag 6000 Shakes dazurechnen
          const startDate = (new Date("2021-06-15T00:00:00").getTime());
          const currentDate = (new Date().getTime());
          const timespan = Math.ceil((currentDate - startDate) / 86400000);
          const dateDiff = timespan * 6000;

          var added_val = Number(sales) - diff + 17800000 + dateDiff;
          setCnt(prev => prev + 1);
          setTickValue(added_val)
        } else {
          errorHandler("Counter: No valid number")
        }
      }
    }



    retrieveData(120);
    let interval = setInterval(retrieveData
      , 10000)
    return () => {
      isApiSubscribed = false;
      clearInterval(interval);
      Tick.DOM.destroy(container);
    };
  }, []);

  // When the tickValue is updated, update the Tick.DOM element
  useEffect(() => {
    if (tickRef.current) {
      tickRef.current.value = tickValue;
    }
  }, [tickValue, cnt]);

  return (
    visible && <div ref={divRef} className={target === "b2b" ? "tick" : "tick b2c"}>
      <div data-repeat="true" data-transform="spring -> round">
        <span data-view="flip">Tick</span>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  target: state.preferences.target
})

export default connect(mapStateToProps)(Counter)